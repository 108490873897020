import React from "react"
import { graphql, Link } from "gatsby"
import { Global } from "@emotion/react"
import Seo from "../components/seo"
import Article from "../components/Article.js"
import theme from "../gatsby-plugin-theme-ui"
import { SectionWrap } from "../components/styles"
import { Header, Hero, Section } from "../components"
import { globalStyles } from "../templates/styles"
import { Themed } from "theme-ui"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import AppStores from "../components/AppStores.js"
import SearchBox from "../components/SeachBox.js"
import TestKnowledge from "../components/TestKnowledge"
import { gameIndex } from "../inGame"

function getLastPartOfUrl(url) {
  const parsedUrl = new URL(url);
  const pathname = parsedUrl.pathname;
  const parts = pathname.split('/');
  const lastPart = parts.pop() || parts.pop(); // Handle trailing slash
  return lastPart;
}
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { nounsJson, transJson, allAudioListJson } = data
  const { definitionsArray, title } = nounsJson

  const hasAudio = allAudioListJson?.nodes?.[0]?.word === title

  var unabbreviated = {
    m: "der",
    f: "die",
    n: "das",
  }

  var genderWord = {
    m: "masculine",
    f: "feminine",
    n: "neuter",
  }

  var descriptionNumber = definitionsArray[0]
  var transDescriptionNumber = transJson.definitionsArray[0]
  const article2Description =
    descriptionNumber.gender && descriptionNumber.gender[1]
      ? unabbreviated[descriptionNumber.gender[1]]
      : ""
  const article3Description =
    descriptionNumber.gender && descriptionNumber.gender[2]
      ? unabbreviated[descriptionNumber.gender[2]]
      : ""
  const genderNoteDescription = transDescriptionNumber.genderNote
    ? transDescriptionNumber.genderNote.en
    : ""
  const genderNoteDescriptionTrim = genderNoteDescription.slice(
    0,
    genderNoteDescription.indexOf("[")
  )

  /* var descriptionBuild = descriptionNumber.gender
    ? `The word ${title} is (gender) ${
        genderWord[descriptionNumber.gender[0]]
      }${
        descriptionNumber.gender[1] && !descriptionNumber.gender[2]
          ? " or " + genderWord[descriptionNumber.gender[1]]
          : descriptionNumber.gender[1]
          ? ", " + genderWord[descriptionNumber.gender[1]]
          : ""
      }${
        descriptionNumber.gender[1] && descriptionNumber.gender[2]
          ? " or " + genderWord[descriptionNumber.gender[2]]
          : ""
      }, therefore the correct article is ${
        unabbreviated[descriptionNumber.gender[0]]
      }${
        descriptionNumber.gender[1] && !descriptionNumber.gender[2]
          ? " or "
          : descriptionNumber.gender[1]
          ? `, `
          : ""
      }${
        descriptionNumber.gender[1] && !descriptionNumber.gender[2]
          ? article2Description
          : article2Description
      }${
        descriptionNumber.gender[1] && descriptionNumber.gender[2] ? " or " : ""
      }${
        descriptionNumber.gender[1] && descriptionNumber.gender[2]
          ? article3Description
          : ""
      }.`
    : ""*/
  const genderDescriptions = descriptionNumber.gender
  var descriptionBuild = ""

  if (genderDescriptions) {
    const gender1 = genderDescriptions[0]
    const gender2 = genderDescriptions[1]
    const gender3 = genderDescriptions[2]

    const article1 = unabbreviated[gender1]
    const article2 = unabbreviated[gender2]
    const article3 = unabbreviated[gender3]
    if (!gender2 && !gender3) {
      descriptionBuild = `${title}, a ${genderWord[gender1]} noun in german, is accompanied by the definite article "${article1}."`
    }

    if (gender3) {
      descriptionBuild = `The word '${title}' in German can be classified as ${genderWord[gender1]}, ${genderWord[gender2]}, or ${genderWord[gender3]}, and accordingly takes the definite articles '${article1},' '${article2},' or '${article3},' respectively.`
    }
    if (gender2 && !gender3) {
      descriptionBuild = `The word '${title}' in German can be classified as ${genderWord[gender1]}, or ${genderWord[gender2]}, and accordingly takes the definite articles '${article1},' or '${article2},' respectively.`
    }

    descriptionBuild += `.`
  }

  const wordForQuiz = gameIndex.splice(
    Math.floor(Math.random() * gameIndex.length),
    1
  )[0]

  return (
    <div style={{}}>
      <Seo
        title={`Is it der, die or das ${title}?`}
        rawTitle={title}
        description={`Which article does ${title} have in German?  ${descriptionBuild} ${genderNoteDescriptionTrim} `}
        isDefinition={true}
        lang={"en"}
        canonical={`${title.replace(/ /g, "_")}`}
        audio={
          definitionsArray[0] && definitionsArray[0].audio?.length
            ? definitionsArray[0].audio[0].src
            : null
        }
        keywords={`${title},Der, Die, Das, Article,articles,gender,feminine,masculine,neuter,grammar,learn,german,nouns,training,game,substantive,substantive,vocabulary,ios,android,app,declension, plural, all forms, nominative`}
      />
      <Global styles={globalStyles} />
      <Header logoTxt={"Der Die Das Train"} />
      <SectionWrap style={{ backgroundColor: "white" }}>
        <Hero imageAlt={"hero.imageAlt"} id="hero" />
        <div style={{}}>
          <SearchBox></SearchBox>
          {definitionsArray.length
            ? definitionsArray.map((number, index) => {
                const definitionText = number.definitions
                  ? transJson.definitionsArray[index].definitionsSplit.map(
                      (definitionEnglish, indexEnglish) => {
                        return (
                          <div key={indexEnglish}>
                            <Themed.p
                              lang="en"
                              style={{ marginTop: 30, fontWeight: "bold" }}
                            >
                              {definitionEnglish.en}
                            </Themed.p>
                            <Themed.i
                              lang="de"
                              key={indexEnglish}
                              style={{
                                margin: 30,
                                color: "#53575A",
                                maxWidth: "80%",
                              }}
                            >
                              {definitionEnglish.de}
                            </Themed.i>
                          </div>
                        )
                      }
                    )
                  : ""
                const examplesText = number.examples
                  ? transJson.definitionsArray[index].examplesSplit.map(
                      (exampleEnglish, indexEnglish) => {
                        return (
                          <div key={indexEnglish}>
                            <Themed.p
                              lang="de"
                              key={indexEnglish}
                              style={{ marginTop: 30, fontWeight: "bold" }}
                            >
                              {exampleEnglish.de}
                            </Themed.p>
                            <Themed.i
                              lang="en"
                              style={{
                                margin: 30,
                                color: "#53575A",
                                maxWidth: "80%",
                              }}
                            >
                              {exampleEnglish.en}
                            </Themed.i>
                          </div>
                        )
                      }
                    )
                  : ""
                const article2 =
                  number.gender && number.gender[1] ? (
                    <Themed.strong>
                      {unabbreviated[number.gender[1]]}
                    </Themed.strong>
                  ) : null
                const article3 =
                  number.gender && number.gender[2] ? (
                    <Themed.strong>
                      {unabbreviated[number.gender[2]]}
                    </Themed.strong>
                  ) : null
                return (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      padding: 20,
                    }}
                  >
                    <div
                      style={{
                        height: "auto",
                        maxWidth: 1024,
                        backgroundColor: "white",
                        marginBottom: "2em",
                        width: "100%",
                      }}
                      backgroundColor={theme.colors.white}
                    >
                      <Themed.h1 style={{ fontWeight: "700" }} lang="en">
                        Is it der, die or das {title}?
                      </Themed.h1>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1rem",
                          maxWidth: "100%",
                          overflow: "scroll",
                          scrollbarWidth: "none",
                        }}
                      >
                        {number.gender && number.gender.length ? (
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            {number.gender.map((gender, i) => {
                              return <Article key={i} gender={gender} />
                            })}
                          </div>
                        ) : null}
                        <Themed.h2 style={{ margin: 0 }}>{title}</Themed.h2>
                        {hasAudio && (
                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                            }}
                            onKeyDown={ev => {
                              if (ev.key === "Enter" || ev.key === "Spacebar") {
                                document.getElementById("audio-file-mp3").play()
                              }
                            }}
                            onClick={() => {
                              console.error(
                                document.getElementById("audio-file-mp3")
                              )
                              document.getElementById(`audio-file-mp3`).play()
                            }}
                            role="button"
                          >
                            <SpeakerDiv>
                              <StaticImage
                                style={{
                                  width: 40,
                                  height: 40,
                                  marginLeft: "1rem",
                                }}
                                src={"../images/speaker.png"}
                                height={40}
                              ></StaticImage>
                            </SpeakerDiv>
                            <audio id={"audio-file-mp3"}>
                              <source
                                src={`https://der-die-das-train.com/api/audio?file=${title}.mp3`}
                                type="audio/mp3"
                              />
                              <track default kind="descriptions" />
                            </audio>
                          </div>
                        )}
                      </div>

                      {number.gender ? (
                        <Themed.p>
                          The correct article in German of {title} is{" "}
                          {unabbreviated[number.gender[0]]}
                          {number.gender[1] && !number.gender[2]
                            ? " or " + unabbreviated[number.gender[1]]
                            : number.gender[1]
                            ? ", " + unabbreviated[number.gender[1]]
                            : ""}
                          {number.gender[1] && number.gender[2]
                            ? " or " + unabbreviated[number.gender[2]]
                            : ""}
                          . So it is {unabbreviated[number.gender[0]]}
                          {number.gender[1] && !number.gender[2]
                            ? " or " + unabbreviated[number.gender[1]]
                            : number.gender[1]
                            ? ", " + unabbreviated[number.gender[1]]
                            : ""}{" "}
                          {number.gender[1] && number.gender[2]
                            ? " or " + unabbreviated[number.gender[2]]
                            : ""}{" "}
                          {title}!<Themed.i> (nominative case)</Themed.i>
                        </Themed.p>
                      ) : null}
                      {number.gender ? (
                        <Themed.p>
                          The word <Themed.strong>{title}</Themed.strong> is{" "}
                          {genderWord[number.gender[0]]}
                          {number.gender[1] && !number.gender[2]
                            ? " or " + genderWord[number.gender[1]]
                            : number.gender[1]
                            ? ", " + genderWord[number.gender[1]]
                            : ""}
                          {number.gender[1] && number.gender[2]
                            ? " or " + genderWord[number.gender[2]]
                            : ""}
                          , therefore the correct article is{" "}
                          {
                            <Themed.strong>
                              {unabbreviated[number.gender[0]]}
                            </Themed.strong>
                          }
                          {number.gender[1] && !number.gender[2]
                            ? " or "
                            : number.gender[1]
                            ? `, `
                            : ""}
                          {number.gender[1] && !number.gender[2]
                            ? article2
                            : article2}
                          {number.gender[1] && number.gender[2] ? " or " : ""}
                          {number.gender[1] && number.gender[2] ? article3 : ""}
                          .
                        </Themed.p>
                      ) : null}

                      <Themed.p lang="en" style={{}}>
                        {transJson.definitionsArray[index].genderNote.en}
                      </Themed.p>

                      {index === 0 ? (
                        <div>
                          <div style={{ marginBottom: "2rem" }}>
                            <Link to={"/articles/der-die-das-gender-rules"}>
                              The Ultimate Guide to German Articles: How to Use
                              Der, Die and Das Correctly (The rules and when
                              they apply)
                            </Link>
                          </div>
                          <TestKnowledge
                            wordForQuiz={wordForQuiz}
                            lang={"en"}
                          />
                        </div>
                      ) : null}

                      {definitionsArray.length > 1 ? (
                        <Themed.h5 style={{ color: "#53575A" }}>{`Context ${
                          index + 1
                        }`}</Themed.h5>
                      ) : null}

                      {number.table ? (
                        <div>
                          <Themed.h2>German declension of {title}?</Themed.h2>
                          <Themed.p>
                            How does the declension of {title} work in the
                            nominative, accusative, dative and genitive cases?
                            Here you can find all forms in the singular as well
                            as in the plural:
                          </Themed.p>
                          <div lang="de" id={"table-outer"}>
                            <div
                              style={{
                                overflowX: "scroll",
                                maxWidth: "90%",
                                scrollbarWidth: "none",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: `${tableStyle}${number.table
                                  .replace("Nominativ", "Nominative")
                                  .replace("Genitiv", "Genitive")
                                  .replace("Dativ", "Dative")
                                  .replace("Akkusativ", "Akkusative")}`,
                              }}
                            ></div>
                          </div>
                        </div>
                      ) : null}

                      <Themed.h3 lang="en" style={{}}>
                        What is the meaning of {title} in German?
                      </Themed.h3>
                      <Themed.p>
                        {number?.definitions?.includes("[2]")
                          ? `${title} has various definitions in German:`
                          : `${title} is defined as:`}
                      </Themed.p>
                      {definitionText}
                      <Themed.h3 lang="en" style={{}}>
                        How to use {title} in a sentence?
                      </Themed.h3>
                      <Themed.p>
                        Example sentences in German using {title} with
                        translations in English.
                      </Themed.p>

                      {examplesText}

                      {definitionsArray[0] &&
                      definitionsArray[0].images?.length &&
                      index === definitionsArray.length - 1 ? (
                        <Themed.h3>Pictures or photos of {title}</Themed.h3>
                      ) : null}
                    </div>
                  </div>
                )
              })
            : null}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                height: "auto",
                maxWidth: 1024,
                backgroundColor: "white",
              }}
              backgroundColor={theme.colors.white}
            >
              <div
                backgroundColor={theme.colors.white}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 20,
                }}
                id="work"
              >
                {definitionsArray[0] && definitionsArray[0].images?.length
                  ? definitionsArray[0].images.map((number, index) => (
                      <div
                        style={{
                          width: 300 * 0.76,
                          margin: 10,
                          display: "flex",
                          flexDirection: "column",
                        }}
                        key={JSON.stringify(number) + index}
                      >
                        <img
                          alt={`${number.txt ? number.txt : title}`}
                          style={{ width: 300 * 0.75, height: 300 * 0.75 }}
                          src={'https://der-die-das-train.com/api/image?file='+getLastPartOfUrl(number.img).replace("220px-",'')}
                        ></img>
                 
                        <div
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            marginTop: 10,
                            backgroundColor: "rgba(238, 237, 237, 0.3)",
                            padding: 10,
                            borderRadius: 10,
                          }}
                        >
                          <div>{number.txt ? number.txt : null}</div>
                          <div>     {}</div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
        <Section
          backgroundColor={theme.colors.secondary}
          id="about"
          light
          enableWave={true}
          waveBottom={false}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "10rem",
              marginBottom: "5rem",
            }}
          >
            <AppStores></AppStores>
          </div>
          <section style={{display:'flex', justifyContent:'center', width:'100%', paddingInline:'1rem'}}>
    
    <Link  style={{color:'white', margin:'0.5rem'}} to={"/privacy-policy"}>Privacy Policy</Link> 
    <Link  style={{color:'white',margin:'0.5rem'}} to={"/terms-and-conditions/"}>Terms and Conditions</Link>



      <span style={{margin:'0.5rem'}}>
        {` `}© {new Date().getFullYear()}
        {` `}
      </span>
      <span style={{ fontWeight: "bold",margin:'0.5rem' }} >
        Der Die Das Train
      </span>
    </section>
        </Section>
      </SectionWrap>
    </div>
  )
}
export const pageQuery = graphql`
  query ($slug: String!) {
    nounsJson(title: { eq: $slug }) {
      title
      warning
      timestamp
      hasDefinitions
      definitionsArray {
        table
        definitions
        examples
        genderNote
        gender
        images {
          img
          txt
        }
        related {
          href
          text
        }
        audio {
          src
          txt
        }
      }
    }

    transJson(title: { eq: $slug }) {
      definitionsArray {
        definitions
        definitionsSplit {
          de
          en
        }
        examples
        examplesSplit {
          de
          en
        }
        genderNote {
          de
          en
        }
      }
      title
    }

    allAudioListJson(filter: { word: { eq: $slug } }) {
      nodes {
        word
      }
    }
  }
`

var tableStyle = `<style>
#table-outer {
  margin-bottom: 0em;
}

.wikitable > tr > th, .wikitable > * > tr > th {
  background-color: #eaecf0;
  text-align: center;
}

.wikitable > tr > th, .wikitable > tr > td, .wikitable > * > tr > th, .wikitable > * > tr > td {
  border: 1px solid #a2a9b1;
  padding: 0.2em 0.4em;
}
th {

  font-weight: bold;
  text-align: -internal-center;
}
.wikitable {
  width:auto;

  color: #202122;
 /* border: 1px solid #a2a9b1;*/
  border-collapse: separate;
  border-radius: 10px !important;


}
table {
  font-size: 100%;
  max-width:100%;
  width:auto;
}
@media only screen and (max-width: 600px) {
  #table-outer {
    margin-bottom: 22em;
  }
}
</style>`

const SpanText = styled.span`
  color: ${"gray"};
`

const SpeakerDiv = styled.div`
  background-color: ${"white"};
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  margin-bottom: 10px;
`

/* 
 <Themed.h2>
                            Finding the right gender of a noun
                          </Themed.h2>
                          <Themed.p>
                            German articles are used similarly to the English
                            articles,<Themed.strong>a</Themed.strong> and{" "}
                            <Themed.strong>the</Themed.strong>. However, they
                            are <Themed.i>declined</Themed.i> differently
                            (change) according to the number, gender and case of
                            their nouns.
                          </Themed.p>
                          <Themed.p>
                            In the German language, the gender and therefore
                            article is fixed for each noun.
                          </Themed.p>


                          <Themed.p>
                            The most difficult part of learning the German
                            language is the articles
                            <Themed.strong> (der, die, das)</Themed.strong> or
                            rather the gender of each noun. The gender of each
                            noun in German has no simple rule. In fact, it can
                            even seem illogical. For example{" "}
                            <Themed.strong>das Mädchen</Themed.strong>, a young
                            girl is neutral while{" "}
                            <Themed.strong>der Junge</Themed.strong>, a young
                            boy is male.
                          </Themed.p>
                          <Themed.p>
                            It is a good idea to learn the correct article for
                            each new word together - even if it means a lot of
                            work. For example learning{" "}
                            <Themed.strong>"der Hund"</Themed.strong> (the dog)
                            rather than just <Themed.strong>Hund</Themed.strong>{" "}
                            by itself. Fortunately, there are some <Link to={'/articles/der-die-das-gender-rules'}>rules</Link> about
                            gender in German that make things a little easier.
                            It might be even nicer if these rules didn't have{" "}
                            <Link to={'/articles/der-die-das-gender-rules'}>exceptions</Link> - but you can't have everything!{" "}
                            <Link to={"/showcase"}>
                              The best way to learn them is with the App -{" "}
                              <Themed.strong>Der-Die-Das Train!</Themed.strong>
                            </Link>{" "}
                            <Themed.strong>
                              (available for iOS and Android)
                            </Themed.strong>{" "}
                          </Themed.p>
                          <Themed.p>
                            German nouns belong either to the gender masculine
                            (male, standard gender) with the definite article
                            der, to the feminine (feminine) with the definite
                            article die, or to the neuter (neuter) with the
                            definite article das.
                          </Themed.p>
                          <ul>
                            <li>
                              <Themed.p>
                                for masculine: points of the compass, weather
                                (Osten, Monsun, Sturm; however it is: das
                                Gewitter), liquor/spirits (Wodka, Wein, Kognak),
                                minerals, rocks (Marmor, Quarz, Granit,
                                Diamant);
                              </Themed.p>
                            </li>
                            <li>
                              <Themed.p>
                                for feminine: ships and airplanes (die
                                Deutschland, die Boeing; however it is: der
                                Airbus), cigarette brands (Camel, Marlboro),
                                many tree and plant species (Eiche, Pappel,
                                Kiefer; aber: der Flieder), numbers (Eins,
                                Million; however it is: das Dutzend), most
                                inland rivers (Elbe, Oder, Donau; aber: der
                                Rhein);
                              </Themed.p>
                            </li>

                            <li>
                              <Themed.p>
                                for neutrals: cafes, hotels, cinemas (das
                                Mariott, das Cinemaxx), chemical elements
                                (Helium, Arsen; however it is: der Schwefel,
                                masculine elements have the suffix -stoff),
                                letters, notes, languages and colors (das
                                Orange, das A, das Englische), certain brand
                                names for detergents and cleaning products
                                (Ariel, Persil), continents, countries (die
                                artikellosen: (das alte) Europa; however
                                exceptions include: der Libanon, die Schweiz …).
                              </Themed.p>
                            </li>
                          </ul>
*/

/*
  {definitionsArray[0] &&
                      definitionsArray[0].audio?.length &&
                      index === definitionsArray.length - 1 ? (
                        <Themed.h3>How do you pronounce {title}?</Themed.h3>
                      ) : null}
                      <div
                        style={{
                          backgroundColor: "white",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        {definitionsArray[0] &&
                        definitionsArray[0].audio?.length
                          ? definitionsArray[0].audio.map((number, index) => {
                              if (number.src) {
                                return (
                                  <div
                                    key={JSON.stringify(number) + index}
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "column",
                                      padding: "10px",
                                    }}
                                    onKeyDown={ev => {
                                      if (
                                        ev.key === "Enter" ||
                                        ev.key === "Spacebar"
                                      ) {
                                        document
                                          .getElementById(`audio${index}`)
                                          .play()
                                      }
                                    }}
                                    onClick={() =>
                                      document
                                        .getElementById(`audio${index}`)
                                        .play()
                                    }
                                    role="button"
                                    tabIndex={index}
                                  >
                                    <SpeakerDiv>
                                      <StaticImage
                                        style={{
                                          width: 40,
                                          height: 40,
                                          marginBottom: 5,
                                        }}
                                        src={"../images/speaker.png"}
                                        height={40}
                                      ></StaticImage>
                                    </SpeakerDiv>
                                    <audio id={`audio${index}`}>
                                      <source
                                        src={number.src}
                                        type="audio/mp3"
                                      />
                                      <track default kind="descriptions" />
                                    </audio>

                                    <SpanText>
                                      {number.txt ? number.txt : null}
                                    </SpanText>
                                  </div>
                                )
                              } else {
                                return <div></div>
                              }
                            })
                          : null}{" "}
                      </div>
*/
